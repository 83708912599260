
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/_content-pages/zero-pawprint-plan",
      function () {
        return require("private-next-pages/_content-pages/zero-pawprint-plan/index.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/_content-pages/zero-pawprint-plan"])
      });
    }
  