import { GetStaticProps } from 'next';
import { useRouter } from 'next/router';

import Newsletter2 from 'components/parts/Newsletter2';
import { Button, ECImage, Section, SectionItem } from 'components/ui';
import ContentBlock from 'components/ui/ContentBlock';
import { ImageSetKeys, getImageSets, useImages } from 'context/ImagesProvider';
import Layout from 'layouts/Layout';
import serverSideSeo from 'services/seo/serverSideSeo';
import serverSideTranslations from 'services/translations/serverSideTranslations';
import { useTranslations } from 'services/translations/TranslationsProvider';
import createNextPage from 'utils/pages/createNextPage';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

export default createNextPage<Props>(
  () => {
    const { t } = useTranslations('page.landing.zeroPawprintPlan');
    const { img } = useImages(ImageSetKeys.PageLandingZeroPawprintPlan);
    const { locale } = useRouter();

    const formattedHref = `https://cdn.edgardcooper.com/pdf/zero_pawprint_plan_${locale}.pdf`;

    return (
      <div className="overflow-hidden">
        <div className="bg-green-600 overflow-hidden relative">
          <div className="container">
            <div className="grid grid-cols-12">
              <div className="col-span-12 lg:col-span-6 flex items-center">
                <div className="py-16 lg:py-20 lg:pr-12 flex-grow">
                  <div className="max-w-copy maxLg:mx-auto maxLg:text-center">
                    <h1 className="font-rodetta text-green-400 text-3xl lg:text-4xl">
                      {t('zeroPPPTitle')}
                    </h1>
                  </div>
                </div>
              </div>
              <div className="col-span-12 lg:col-span-6 relative">
                <ECImage
                  img={img('heroZeroPPP')}
                  srcOptions={{
                    w: 1500,
                    q: 90,
                  }}
                  className="w-full h-full object-cover object-center"
                  pictureClassName="block aspect-[4/3] w-auto -mx-4 sm:-mx-8 lg:mx-0 lg:min-h-full lg:h-full lg:w-[50vw] 2xl:max-h-[60vh]"
                />
              </div>
            </div>
          </div>
        </div>

        <Section
          color="white"
          className="container--md space-y-4 text-green-600 font-rooney font-semibold mx-auto flex items-center text-center px-4"
        >
          <h3>{t('zeroPPPStatement1')}</h3>
          <h3>{t('zeroPPPStatement2')}</h3>
          <h3>{t('zeroPPPStatement3')}</h3>
        </Section>

        <Section className="bg-brown-400" noBottomSpacing>
          <ContentBlock className="container gap-4">
            <div className="">
              <div className="max-w-copy md:text-center lg:text-left maxLg:mx-auto mb-8">
                <div>
                  <div className="font-rooney font-medium text-lg text-purple-700 flex flex-col gap-6">
                    <h3 className="text-3xl lg:text-4xl text-green-600 font-rodetta text-left">
                      {t('zeroPPPCarbonTitle')}
                    </h3>
                    <p className="text-green-600 text-xl text-left">
                      {t('zeroPPPCarbonDescription')}
                    </p>
                  </div>
                </div>
              </div>
              <div className="inline-block space-y-2">
                <Button
                  external
                  href="/blog/how-we-want-to-reduce-our-environmental-impact-by-2025/"
                  color="green"
                  className="!block"
                >
                  {t('readMore')}
                </Button>
                <Button color="green" href="/carbon-scores">
                  Carbon Scores
                </Button>
              </div>
            </div>
            <div className="">
              <ECImage
                img={img('zeroPPPZeroCarbon')}
                srcOptions={{
                  w: 1200,
                }}
                className=" justify-self-end lg:w-[500px] object-cover object-center"
              />
            </div>
          </ContentBlock>
        </Section>

        <Section className="bg-brown-400" noBottomSpacing>
          <ContentBlock reverse className="container gap-4">
            <div className="">
              <div className="mb-8">
                <div>
                  <div className="font-rooney font-medium text-lg text-purple-700 flex flex-col gap-6">
                    <h3 className="text-3xl lg:text-4xl text-green-600 font-rodetta ">
                      {t('zeroPPPSustainablePackagingTitle')}
                    </h3>
                    <p className="text-green-600 text-xl  ">
                      {t('zeroPPPSustainablePackagingDescription')}
                    </p>
                  </div>
                </div>
              </div>
              <Button
                external
                href="/blog/great-news-all-of-our-bags-can-now-go-in-your-paper-recycling/"
                color="green"
              >
                {t('readMore')}
              </Button>
            </div>
            <div className="">
              <ECImage
                img={img('zeroPPPPackaging')}
                srcOptions={{
                  w: 1200,
                }}
                className="justify-self-end  object-cover lg:w-[500px] object-center"
              />
            </div>
          </ContentBlock>
        </Section>

        <Section className="bg-brown-400">
          <ContentBlock className="container gap-4">
            <div className="">
              <div className="max-w-copy md:text-center lg:text-left maxLg:mx-auto mb-8">
                <div>
                  <div className="font-rooney font-medium text-lg text-purple-700 flex flex-col gap-6">
                    <h3 className="text-3xl lg:text-4xl text-green-600 font-rodetta text-left">
                      {t('zeroPPPEthicalSourcingTitle')}
                    </h3>
                    <p className="text-green-600 text-xl text-left">
                      {t('zeroPPPEthicalSourcingDescription')}
                    </p>
                  </div>
                </div>
              </div>
              <Button
                external
                color="green"
                href="/blog/how-we-re-going-to-achieve-100-ethical-sourcing-of-our-ingredients/"
              >
                {t('readMore')}
              </Button>
            </div>
            <div className="">
              <ECImage
                img={img('zeroPPPEthicalSourcing')}
                srcOptions={{
                  w: 1200,
                }}
                className="justify-self-end object-cover object-center lg:w-[500px]"
              />
            </div>
          </ContentBlock>
        </Section>

        <Section color="green">
          <div className="container">
            <div className="max-w-[900px] mx-auto p-9 bg-white rounded-2xl flex items-center flex-col">
              <ECImage
                img={img('zeroPPP2025')}
                srcOptions={{
                  w: 1200,
                }}
                className="object-cover lg:max-w-xl object-center"
              />
              <p className="font-rooney text-green-600 font-bold text-center lg:mx-16">
                {t('zeroPPP2025Description')}
              </p>
              <div className="mt-10">
                <Button external href={formattedHref} className="!bg-green-600">
                  {t('zeroPPP2025Cta')}
                </Button>
              </div>
            </div>
          </div>
        </Section>

        <Section>
          <ContentBlock reverse className="container">
            <div className="">
              <div className="max-w-copy md:text-center lg:text-left maxLg:mx-auto mb-8">
                <div>
                  <div className="font-rooney font-medium text-lg text-purple-700 flex flex-col gap-6">
                    <h3 className="text-3xl lg:text-4xl text-green-600 font-rodetta text-left">
                      {t('zeroPPPDiscoverInitiativesTitle')}
                    </h3>
                    <p className="text-green-600 text-xl text-left">
                      {t('zeroPPPDiscoverInitiativesDescription')}
                    </p>
                  </div>
                </div>
              </div>
              <Button
                external
                href="/blog/how-we-want-to-reduce-our-environmental-impact-by-2025/"
                color="green"
              >
                {t('zeroPPPReadTargetBlogs')}
              </Button>
            </div>
            <div className="">
              <ECImage
                img={img('zeroPPPInitiatives')}
                srcOptions={{
                  w: 1200,
                }}
                className="justify-self-end object-cover object-center"
              />
            </div>
          </ContentBlock>
        </Section>

        <Section color="brown-100" noBottomSpacing>
          <SectionItem>
            <Newsletter2 />
          </SectionItem>
        </Section>
      </div>
    );
  },
  {
    Layout,
  },
);

export const getStaticProps: GetStaticProps = async (ctx) => {
  const { locale } = ctx;
  if (locale === 'default') return { props: {} };

  const [resources, images, seo] = await Promise.all([
    serverSideTranslations(
      locale,
      ['page.landing.zeroPawprintPlan', 'content.testimonials', 'content.newsletter', 'form'],
      ctx,
    ),
    getImageSets(
      {
        locale,
        keys: [ImageSetKeys.PageLandingZeroPawprintPlan],
      },
      ctx,
    ),
    serverSideSeo(locale, 'page.landing.zeroPawprintPlan', ctx),
  ]);

  return !resources
    ? { notFound: true }
    : {
        props: {
          resources,
          seo,
          images,
        },
        revalidate: parseInt(process.env.NEXT_REVALIDATE_SECONDS, 10),
      };
};
